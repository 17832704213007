import React from "react";
import logo from "../assets/logo.svg";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full flex items-center justify-center px-[20px]">
      <div className="w-full h-[80px] flex z-[99999] items-center justify-between max-w-[1260px] ">
        <img onClick={() => navigate("/")} src={logo} alt="" />
        <span
          onClick={() => navigate("/privacy")}
          className=" cursor-pointer text-[#fff] font-[600] w-[111px] h-[40px] flex items-center justify-center rounded-[5px]  bg-[#000] text-[10px] "
        >
          Privacy
        </span>
      </div>
    </div>
  );
};

export default Navbar;
