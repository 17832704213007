import React from "react";
import logo from "../assets/logo.svg";
import store1 from "../assets/store1.svg";
import store2 from "../assets/store2.svg";
import ndpr from "../assets/ndpr.svg";
import { TbMailFilled, TbPhoneFilled } from "react-icons/tb";

const Footer = () => {
  return (
    <div className="w-full flex justify-center sm:px-[20px] z-[999]  ">
      <div className="w-full  min-h-[150px]  bg-[#000] flex gap-[20px] sm:flex-row flex-col-reverse items-center justify-between max-w-[1200px]  sm:rounded-[40px] p-[20px] sm:px-[40px] ">
        <div className="flex flex-col gap-[12px] sm:w-fit w-full sm:items-start items-center">
          <div className="flex flex-col sm:items-start items-center">
            <img
              className="h-[40px] mb-2 w-fit sm:block hidden"
              src={logo}
              alt=""
            />
            {/* <span className="text-[#F0741A]">Dolla</span> */}
            <span className="text-[#fff] text-[14px] sm:text-start text-center ">
              Powered By Flexgreen Concept Ltd
            </span>
          </div>
          <div className="flex flex-col text-[#fff] text-[12px] sm:items-start items-center">
            <span>38 Amino Kano crescent, Abuja, Nigeria.</span>
            <div className="flex gap-[24px]">
              <span className="flex items-center gap-1">
                <TbMailFilled />
                hello@dolla.cards
              </span>
              <span className="flex items-center gap-1">
                <TbPhoneFilled />
                (+234) 907 599 2864
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-[20px] sm:w-fit w-full sm:items-start items-center">
          <div className="flex gap-[20px]  items-center">
            <span
              onClick={() =>
                window.open("https://testflight.apple.com/join/1eqVfagj")
              }
              className="relative w-fit h-fit cursor-pointer"
            >
              <span className="text-[8px] absolute top-[-5px] right-0 bg-[#ebad31] px-[6px] rounded-full text-white">
                Get Beta Access
              </span>
              <img
                src={store1}
                className="border h-[40px] rounded-[100px]"
                alt=""
              />
            </span>
            <span
              onClick={() => window.open("https://forms.gle/VUoc3mVjiG5ccPV17")}
              className="relative w-fit h-fit cursor-pointer"
            >
              <span className="text-[8px] absolute top-[-5px] right-0 bg-[grey] px-[6px] rounded-full text-white">
                Get Access
              </span>
              <img
                src={store2}
                className="border h-[40px] rounded-[100px]"
                alt=""
              />
            </span>
          </div>
          <div className="flex sm:justify-end   rounded-[10px]">
            <img src={ndpr} className="h-[40px] " alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
